<template>
    <p
        :class="{
            'alert--info': type === 'info',
            'alert--success': type === 'success',
            'alert--danger': type === 'danger',
            'alert--warning': type === 'warning',
        }"
        class="alert"
        role="alert"
    >
        <button v-if="closable" class="h-3 w-3" @click="close()">
            <svg-vue class="icon mx-auto h-2 w-2" icon="close" />
        </button>

        <span v-if="title" class="alert__title">{{ title }}</span>

        <span class="alert__message"><slot></slot></span>
    </p>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: 'info',
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        closable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        close() {
            // destroy the vue listeners, etc
            this.$destroy()

            // remove the element from the DOM
            this.$el.parentNode.removeChild(this.$el)
        },
    },
}
</script>
