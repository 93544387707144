import httpClient from '../../shared/services/HttpClient'
import { format } from '../../shared/services/DateService'
import { replaceQueryFilterParameter } from '../../shared/utils'

const initialState = {
    classes: [],
    domain: {},
    filter: {},
    groups: [],
    maxDate: null,
    minDate: null,
    schools: [],
    averages: null,
    user: null,
    isLoading: true,
    microGames: [],
}

const formatDate = (field) =>
    field ? format(new Date(field), 'dd-MM-yyyy') : null

export default {
    strict: true,
    namespaced: true,
    state: initialState,
    actions: {
        async fetchDebriefing({ commit, state, getters }) {
            commit('setLoadingState', true)
            const response = await httpClient.get(
                '/api/micro-game-collections/rock-it/custom-data/debriefing',
                {
                    'filter[userId]': state.user.id,
                    'filter[microGameId]': getters.selectedMicroGame.id,
                },
            )

            // Empty response is converted to an array
            if (Array.isArray(response.data)) {
                commit('setDebriefing', null)
                commit('setLoadingState', false)
                return
            }
            if (response.data.type !== 'rockItResult') {
                console.error('Received wrong kind of data...')
                return
            }

            commit('setLoadingState', false)
            commit('setDebriefing', response.data.attributes)
        },
        async fetchAverages({ commit, state }) {
            commit('setLoadingState', true)
            const response = await httpClient.get(
                '/api/micro-game-collections/rock-it/custom-data/averages',
                {
                    'filter[startedAt]': formatDate(state.filter.startedAt),
                    'filter[endedAt]': formatDate(state.filter.endedAt),
                    'filter[groupId]': state.filter.groupId,
                    'filter[schoolId]': state.filter.schoolId,
                    'filter[classId]': state.filter.classId,
                },
            )

            // Empty response is converted to an array
            if (Array.isArray(response.data)) {
                commit('setAverages', null)
                commit('setLoadingState', false)
                return
            }
            if (response.data.type !== 'rockItAverages') {
                console.error('Received wrong kind of data...')
                return
            }

            commit('setLoadingState', false)
            commit('setAverages', response.data.attributes)
        },
        updateFilter({ commit }, payload) {
            commit('updateFilter', payload)
            replaceQueryFilterParameter(...payload)
        },
    },
    mutations: {
        setAverages(state, averages) {
            state.averages = averages
        },
        setDebriefing(state, debriefing) {
            if (debriefing) {
                debriefing.playedAt = new Date(debriefing.playedAt)
            }
            state.debriefing = debriefing
        },
        updateFilter(state, [field, value]) {
            state.filter[field] = value
        },
        setLoadingState(state, value) {
            state.isLoading = value
        },
    },
    getters: {
        selectedMicroGame(state) {
            if (state.filter?.microGameId) {
                return state.microGames.find(
                    (mg) => mg.id === state.filter.microGameId,
                )
            }
            return state.microGames[0]
        },
    },
}
