<template>
    <div
        class="relative"
        :style="{ zIndex: isOpen ? 999 : null }"
        @focusout="isOpen = hideOptionsIfFocusLost($event)"
    >
        <button class="border-0 bg-transparent" @click="isOpen = !isOpen">
            <slot name="button"></slot>
        </button>
        <div
            v-show="isOpen"
            :style="{
                left: align === 'left' ? `${offset}px` : 'auto',
                right: align === 'right' ? `${offset}px` : 'auto',
            }"
            class="focus:outline-none absolute"
            tabindex="0"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { isChildOf } from '../utils'

export default {
    props: {
        align: {
            type: String,
            default: 'left',
            required: false,
            validator: (val) => ['left', 'right'].includes(val),
        },
        offset: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        hideOptionsIfFocusLost(event) {
            return (
                event instanceof FocusEvent &&
                event.relatedTarget !== null &&
                isChildOf(this.$el, event.relatedTarget)
            )
        },
    },
}
</script>
