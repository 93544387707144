<template>
    <svg :viewBox="viewBox" height="100%" width="100%" v-html="content"></svg>
</template>

<script>
import httpClient from '../services/HttpClient'
import { strToElement } from '../utils'

export default {
    props: {
        href: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            viewBox: undefined,
            content: '',
        }
    },
    async mounted() {
        try {
            const raw = await httpClient.get(this.href, null, 'text')
            const content = strToElement(raw)
            this.content = content.innerHTML.replace(
                /#[0-9a-f]{3,6}/gi,
                'currentColor',
            )
            this.viewBox = content.getAttribute('viewBox')
        } catch (err) {
            console.warn('Failed to load icon with error:', err)
        }
    },
}
</script>
