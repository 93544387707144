import { clamp } from 'lodash/number'
import httpClient from '../../shared/services/HttpClient'
import ObjectType from '../../shared/services/LearningObjectType'
import { t } from '../../shared/services/TranslationService'

/**
 * @type {WhiteBox.LearningModuleState}
 */
const defaultState = {}

const NOT_COMPLETABLE_BY_DEFAULT = [
    ObjectType.MISSION,
    ObjectType.FLASHCARD,
    ObjectType.QUIZ,
]
const INDICATORS_PER_SIDE = 2
const WINDOW_SIZE = INDICATORS_PER_SIDE * 2 + 1

export default {
    namespaced: true,
    state: defaultState,
    mutations: {
        quizToggleAnswer(state, answerId) {
            const idx = state.meta.selectedAnswers.indexOf(answerId)
            if (idx === -1) {
                state.meta.selectedAnswers.push(answerId)
            } else {
                state.meta.selectedAnswers.splice(idx, 1)
            }
        },
        completionResponseReceived(state, meta) {
            state.meta = {
                ...state.meta,
                ...meta,
            }
        },
        setContinueButtonKey(state, textKey) {
            state.meta.textKey = textKey
        },
        flashcardFlip({ object }, flashcardId) {
            const flashcard = object.flashcards.find(
                (fc) => fc.id === flashcardId,
            )
            flashcard.flipped = !flashcard.flipped
        },
        flashcardSetCompleted({ object }, { flashcardId, completed }) {
            const idx = object.flashcards.findIndex(
                (fc) => fc.id === flashcardId,
            )
            const [flashcard] = object.flashcards.splice(idx, 1)

            flashcard.completed = completed
            flashcard.flipped = false
            object.flashcards.push(flashcard)
        },
        setImageModalState(state, modalState) {
            state.modal = modalState
        },
        quizNextQuestion(state) {
            state.meta = {
                ...state.meta,
                currentQuestion: state.meta.currentQuestion + 1,
                selectedAnswers: [],
                feedbackType: null,
                textKey: 'learning.learning_object_quiz_check_answers',
            }
        },
        quizResetState(state) {
            state.meta = {
                ...state.meta,
                currentQuestion: 0,
                selectedAnswers: [],
                feedbackType: null,
            }
        },
        quizDisplayFeedbackType(state) {
            state.meta.feedbackType = Object.values(
                state.meta.resultAnswers,
            ).every((v) => v === true)
        },
        setCompleted(state, completed = false) {
            state.meta.completed = completed
        },
    },
    getters: {
        pagination({ navigation: { pagination } }) {
            const objectCount = pagination.length
            if (objectCount <= WINDOW_SIZE) {
                return {
                    pre: 0,
                    post: 0,
                    window: pagination,
                }
            }

            // Clamp the pivot point to the lowest and highest possible index
            const pivot = clamp(
                pagination.findIndex((p) => p.active),
                INDICATORS_PER_SIDE,
                objectCount - INDICATORS_PER_SIDE - 1,
            )

            const lowerBound = pivot - INDICATORS_PER_SIDE
            const upperBound = pivot + 1 + INDICATORS_PER_SIDE
            const window = pagination.slice(lowerBound, upperBound)

            return {
                window,
                pre: Math.max(lowerBound, 0),
                post: Math.max(objectCount - upperBound, 0),
            }
        },
        isCompletable(state) {
            switch (state.object.type) {
                case ObjectType.QUIZ:
                    return state.meta.selectedAnswers?.length > 0
                case ObjectType.MISSION:
                    return true
                default:
                    return false
            }
        },
        isContinuable(state) {
            return state.meta.completed === true
        },
        shouldContinueAfterProgressRegister(state) {
            return state.object.type !== ObjectType.QUIZ
        },
        endpointUrl(state) {
            return state.session.endpoint.replace(':object', state.object.id)
        },
        objectPayload(state, getters) {
            const payload = {}

            switch (state.object.type) {
                case ObjectType.QUIZ:
                    payload.current = state.meta.currentQuestion
                    payload.question_id = getters.quizCurrentQuestion.id
                    payload.answers = state.meta.selectedAnswers
                    break
                default:
                    break
            }

            return payload
        },
        quizCurrentQuestion(state) {
            return state.object.questions[state.meta.currentQuestion ?? 0]
        },
        quizCurrentFeedback(state, getters) {
            const type = state.meta.feedbackType
            if (type === null) {
                return null
            }
            const feedback = { type }
            const question = getters.quizCurrentQuestion
            feedback.message =
                type === true
                    ? question.positive_feedback ||
                      t('learning.learning_object_quiz_success_message')
                    : question.negative_feedback ||
                      t('learning.learning_object_quiz_failure_message')
            return feedback
        },
        quizCurrentAnswersById(state, getters) {
            return getters.quizCurrentQuestion.answers.reduce((acc, curr) => {
                acc[curr.id] = curr
                return acc
            }, {})
        },
        flashcardCurrent(state) {
            return state.object.flashcards?.find((fc) => !fc.completed)
        },
    },
    actions: {
        handleMissionResponse({ commit }) {
            commit('setCompleted', true)
        },
        handleQuizResponse({ state, commit, getters }) {
            const {
                meta: { currentQuestion, selectedAnswers },
                mode,
                object,
            } = state

            if (mode === 'preview') {
                const answersById = getters.quizCurrentAnswersById
                commit('completionResponseReceived', {
                    resultAnswers: selectedAnswers.reduce(
                        (r, answerId) => ({
                            ...r,
                            [answerId]: answersById[answerId].is_correct,
                        }),
                        {},
                    ),
                })
            }

            commit('quizDisplayFeedbackType')

            if (currentQuestion === object.questions.length - 1) {
                commit('setContinueButtonKey', null)
                commit('setCompleted', true)
            } else {
                commit(
                    'setContinueButtonKey',
                    'learning.learning_object_quiz_next_question',
                )
            }
        },
        async submitForCompletion({ state, commit, getters, dispatch }) {
            if (
                state.object.type === ObjectType.QUIZ &&
                state.meta.feedbackType !== null
            ) {
                commit('quizNextQuestion')
                return { continue: false }
            }

            if (state.mode === 'preview') {
                await dispatch('handleQuizResponse')
                return { continue: false }
            }

            const response = await httpClient.post(
                getters.endpointUrl,
                getters.objectPayload,
            )

            commit('completionResponseReceived', response)

            switch (state.object.type) {
                case ObjectType.QUIZ:
                    await dispatch('handleQuizResponse')
                    break
                case ObjectType.MISSION:
                    await dispatch('handleMissionResponse')
                    break
                default:
                    break
            }

            return {
                completed: response.completed,
                continue: getters.shouldContinueAfterProgressRegister,
            }
        },
        checkInitialCompletion({ state, dispatch }) {
            if (NOT_COMPLETABLE_BY_DEFAULT.includes(state.object.type)) {
                return Promise.resolve(false)
            }

            return dispatch('submitForCompletion')
        },
        flashcardOptionSelected(
            { commit, getters, dispatch },
            { id: flashcardId, completed },
        ) {
            commit('flashcardSetCompleted', { flashcardId, completed })

            // If no more flashcards are left, submit for completion
            if (typeof getters.flashcardCurrent === 'undefined') {
                dispatch('submitForCompletion')
            }
        },
        showImageModal({ commit }, { imageUrl }) {
            commit('setImageModalState', {
                visible: true,
                imageUrl,
            })
        },
        closeImageModal({ commit }) {
            commit('setImageModalState', {
                visible: false,
            })
        },
    },
}
