<template>
    <a :href="actualHref">
        <slot />
    </a>
</template>

<script>
import { isMobileDevice } from '../services/UserAgentService'

export default {
    inheritAttrs: true,
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        altHref: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
    },
    computed: {
        actualHref() {
            if (this.disabled) {
                return null
            }
            if (isMobileDevice()) {
                return this.altHref
            }
            return this.href
        },
    },
}
</script>
