export const sleep = (ms) =>
    new Promise((res) => {
        setTimeout(res, ms)
    })

/**
 * Debounce a function for the given time
 * @param {Function} fn
 * @param {number} t
 * @returns {Function}
 */
export const debounce = (fn, t = 250) => {
    let timeout

    return function debouncedFn(...args) {
        const functionCall = () => fn.apply(this, args)

        clearTimeout(timeout)
        timeout = setTimeout(functionCall, t)
    }
}

/**
 * Pad a number with a certain amount of zeros
 * @param {number} n
 * @param {number} length
 * @param {string} char
 * @returns {string}
 */
export const padNumber = (n, length = 2, char = '0 ') =>
    n.toString().padStart(length, char)

/**
 * Check if a given child is in the tree of the given parent
 * @param {HTMLElement} parent
 * @param {HTMLElement} child
 */
export const isChildOf = (parent, child) => {
    if (typeof parent?.contains === 'function') {
        return parent.contains(child)
    }
    if (child === document.body || child === null) {
        return false
    }
    if (child === parent) {
        return true
    }
    return isChildOf(parent, child.parentElement)
}

export const getLocale = () => document.querySelector('html').lang.substr(0, 2)

/**
 * Convert a string into an element
 * @param str
 * @returns {Element}
 */
export const strToElement = (str) => {
    const wrapper = document.createElement('div')
    wrapper.innerHTML = str
    return wrapper.firstElementChild
}

/**
 *
 * @param {string} str
 * @param length
 * @param overflow
 * @return {*}
 */
export const truncate = (str, length = 60, overflow = '...') => {
    if (!str || str.length < length) {
        return str
    }

    return str.substr(0, length - overflow.length) + overflow
}

/**
 *
 * @param {Object.<string, string|Blob>} obj
 * @return {FormData}
 */
export const intoFormData = (obj) => {
    const formData = new FormData()
    Object.entries(obj).forEach(([key, val]) => {
        formData.append(key, val)
    })

    return formData
}

/**
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string}
 */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) {
        return '0 Bytes'
    }

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

/**
 * @param {Blob} blob
 * @param {number} length
 * @param {number} size
 * @returns {Blob[]}
 */
export const blobChunks = (blob, length, size) => {
    return Array.from({ length }, (_, i) =>
        blob.slice(i * size, Math.min(i * size + size, blob.size), blob.type),
    )
}

export const replaceQueryFilterParameter = (key, value) => {
    const url = new URL(window.location)
    if (value) {
        url.searchParams.set(`filter[${key}]`, value)
    } else {
        url.searchParams.delete(`filter[${key}]`)
    }
    window.history.replaceState({}, '', url.toString())
}

/**
 * Clamp a number between a min and max
 * @param {number} num
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export const wrap = (items) => {
    if (!items) {
        return []
    }
    return Array.isArray(items) ? items : [items]
}

export const range = (start, end) =>
    Array.from(
        { length: Math.max(end, start) - start + 1 },
        (_, idx) => start + idx,
    )
