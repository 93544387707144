import httpClient from '../../shared/services/HttpClient'

const defaultState = {
    show: false,
    content: '',
}

export default {
    namespaced: true,
    state: defaultState,
    mutations: {
        setVisible(state, show = true) {
            state.show = show
        },
        setContent(state, content = '') {
            state.content = content
        },
    },
    actions: {
        async display({ commit }) {
            const tutorial = await httpClient.get('/api/organisation/tutorial')
            commit('setContent', tutorial.data)
            commit('setVisible', true)
        },
        finish({ commit, rootState, dispatch }) {
            if (rootState.user.meta.hasSeenTutorial !== true) {
                dispatch('markUserAsViewedTutorial', null, { root: true })
            }

            commit('setVisible', false)
        },
    },
    getters: {},
}
