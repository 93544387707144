export const setUser = (state, user) => {
    state.user = user
}

export const setOrganisation = (state, organisation) => {
    state.organisation = organisation
}

export const updateUserMeta = (state, meta) => {
    state.user.meta = {
        ...state.user.meta,
        ...meta,
    }
}
