import HttpError from '../errors/HttpError'
import { blobChunks, intoFormData } from '../utils'

const ACCEPTED_RESPONSE_TYPES = ['blob', 'arraybuffer', 'json', 'text']

/**
 * @typedef ProgressCb
 * @property {number} progress
 */

export class HttpClient {
    #bearer

    constructor(bearer) {
        this.#bearer = bearer
    }

    getBearer = () => document.querySelector('meta[name="api-token"]')?.content

    /**
     * @template {T=any}
     * @param {string} url
     * @param {?object} headers
     * @param {string} responseType
     * @param {?any} content
     * @param {Record<string, any>} options
     * @returns {Promise<T>}
     */
    request = (url, { headers, responseType, body: content, ...options }) => {
        if (!ACCEPTED_RESPONSE_TYPES.includes(responseType)) {
            throw new Error(`Invalid response type ${responseType}`)
        }

        if (!this.#bearer) {
            this.#bearer = this.getBearer()
        }

        const body = this.getBody(content)
        const realHeaders = {
            Authorization: `Bearer ${this.#bearer}`,
            Accept: 'application/json, text/plain',
            ...headers,
        }

        if (this.shouldSerializeContent(content)) {
            realHeaders['Content-Type'] = 'application/json'
        }

        return (
            fetch(url, {
                headers: realHeaders,
                cache: 'no-cache',
                body,
                ...options,
            })
                // Convert the response to the requested responsetype
                .then((response) => {
                    if (!response.ok) {
                        return HttpError.fromResponse(response)
                    }
                    return response[responseType]()
                })
                .then((finish) => {
                    if (finish instanceof Error) {
                        throw finish
                    }
                    return finish
                })
        )
    }

    #parseUrl = (strUrl, params) => {
        const url = new URL(
            strUrl,
            strUrl.startsWith('/') ? document.location.origin : undefined,
        )
        if (params !== null) {
            Object.entries(params).forEach(([key, val]) => {
                if (val !== null && val !== undefined && val.length > 1) {
                    url.searchParams.append(key, val)
                }
            })
        }
        return url
    }

    get = (url, params = null, accept = 'json') =>
        this.request(this.#parseUrl(url, params).toString(), {
            method: 'GET',
            responseType: accept,
        })

    post = (url, body = {}, accept = 'json') =>
        this.request(url, {
            method: 'POST',
            body,
            responseType: accept,
        })

    /**
     *
     * @param {string} url
     * @param {File} file
     * @returns {Promise<WhiteBox.MediaStoreResponse, HttpError>}
     */
    uploadFile = (url, file) => {
        const body = new FormData()
        body.append('file', file)
        return this.request(url, {
            method: 'POST',
            responseType: 'json',
            body,
        })
    }

    /**
     * Upload a partial file
     * @param {string} route
     * @param {Blob} chunk
     * @param {number} chunkId
     * @returns {Promise<Record<string, *>>}
     */
    #uploadFileChunk = async (route, chunk, chunkId) =>
        this.request(route, {
            method: 'POST',
            responseType: 'json',
            body: intoFormData({ chunk, chunkId }),
        })

    #requestUploadRoute = async (route, file) =>
        this.request(route, {
            method: 'POST',
            responseType: 'json',
            body: intoFormData({
                fileName: file.name,
                fileType: file.type,
                fileSize: file.size,
            }),
        }).then((response) =>
            response.success ? response : Promise.reject(response),
        )

    /**
     * @param storeRoute
     * @param {File} file
     * @param {function(number): void} onProgress callback for progress
     */
    uploadFileChunked = async (storeRoute, file, onProgress) => {
        // First we ask the server to create a file to upload to
        try {
            /** @type {WhiteBox.MediaPartialStoreResponse} response */
            const response = await this.#requestUploadRoute(storeRoute, file)
            const chunks = blobChunks(
                file,
                response.chunk_count,
                response.chunk_size,
            )

            const results = []
            for (let i = 0; i < chunks.length; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                results[i] = await this.#uploadFileChunk(
                    response.upload_url,
                    chunks[i],
                    i,
                )
                onProgress((i + 1) / chunks.length)
            }

            return {
                success: results.every((r) => r.success === true),
                id: response.id,
            }
        } catch (error) {
            return {
                success: false,
            }
        }
    }

    getBody = (content) => {
        if (this.shouldSerializeContent(content)) {
            return JSON.stringify(content)
        }
        return content
    }

    shouldSerializeContent = (content) =>
        !(
            content instanceof File ||
            content instanceof FormData ||
            content instanceof URLSearchParams ||
            typeof content === 'string'
        )
}

const httpClient = new HttpClient()

export default httpClient
