import { defineAsyncComponent } from 'vue'

import MessageAlert from '../shared/components/MessageAlert'
import DropDown from '../shared/components/DropDown'
import MessageList from '../shared/components/MessageList'
import WebSvg from '../shared/components/WebSvg'
import MobileLink from '../shared/components/MobileLink'

const defineComponent = (path) =>
    defineAsyncComponent(() => import(`./${path}.vue`))
const defineSharedComponent = (path) =>
    defineAsyncComponent(() => import(`../shared/${path}.vue`))

export default {
    MessageAlert,
    MessageList,
    DropDown,
    WebSvg,
    MobileLink,
    MicroGameProgressPage: defineComponent('pages/games/MicroGameProgressPage'),
    MicroGameStatisticsPage: defineComponent(
        'pages/games/MicroGameStatisticsPage',
    ),
    MicroGameLeaderboardPage: defineComponent(
        'pages/games/MicroGameLeaderboardPage',
    ),
    RockItAveragesPage: defineComponent(
        'pages/games/custom-data/RockItAveragesPage',
    ),
    RockItDebriefingPage: defineComponent(
        'pages/games/custom-data/RockItDebriefingPage',
    ),
    GraphItem: defineComponent('components/charts/GraphItem'),
    ToolTip: defineComponent('components/ToolTip'),
    TrainingIndexPage: defineComponent('pages/training/TrainingIndexPage'),
    ProfilePage: defineComponent('pages/ProfilePage'),
    ProgressGraph: defineComponent('components/training/ProgressGraph'),
    QuizResultsBar: defineComponent('components/training/quiz/QuizResultsBar'),
    ActivityChart: defineComponent('components/ActivityChart'),
    CollapseContainer: defineSharedComponent('components/CollapseContainer'),
    LearningCard: defineComponent('components/learning/LearningCard'),
    LearningContainer: defineComponent('containers/learning/LearningContainer'),
    SessionDetailsContainer: defineComponent(
        'containers/learning/SessionDetailsContainer',
    ),
    DayScoreGraph: defineSharedComponent('components/learning/DayScoreGraph'),
    RichEditor: defineSharedComponent('components/RichEditor'),
    PortalTutorial: defineComponent('containers/PortalTutorial'),
}
