import { createI18n } from 'vue-i18n'
import { getLocale } from '../utils'

/**
 * i18n Listener
 * @type {import('vue-i18n').VueI18n|undefined}
 */
let listener
/**
 * Keep track of the loaded translation files
 * @type {Set<string>}
 */
const loadedLangs = new Set()

const isLoaded = (name) => loadedLangs.has(name)

/**
 * Dynamically import a translation file and cache it
 * @param name
 * @return {Promise<LocaleMessages>}
 */
const load = (name) => {
    console.warn(`[Translation Service] async loading bundle "${name}"`)
    return import(`../../langs/${name}.js`).then((mod) => mod.default)
}

/**
 * Publish the languagemap to our listener
 * @param {string} name
 * @param {LocaleMessages} contents
 */
const publish = (name, contents) => {
    if (!listener) {
        throw new Error('Listener is not initialized!')
    }
    Object.entries(contents).forEach(([lang, messages]) => {
        listener.mergeLocaleMessage(lang, { [name]: messages })
    })
    return loadedLangs
}

/**
 * Register a language bundle as initialized
 * @param name
 * @returns {void}
 */
const register = (name) => {
    loadedLangs.add(name)
}

/**
 * Register a VueI18n Listener
 */
export const setListener = (i18n) => {
    listener = i18n
}

/**
 * Translate the given key
 *
 * @param {import('vue-i18n').Key} key
 * @param {unknown[]} args
 * @return {import('vue-i18n').TranslateResult}
 */
export const t = (key, args = []) => {
    const actualKey = Array.isArray(key) ? key[0] : key
    return listener.t(actualKey, args)
}

/**
 * Ensure that a language bundle is loaded
 * @param {string} name
 * @param {undefined|import('vue-i18n').LocaleMessages} messages
 * @returns {Promise<Set>}
 */
export const ensureLoaded = async (name, messages = undefined) => {
    if (isLoaded(name)) {
        return Promise.resolve()
    }

    register(name)

    const contents = messages || (await load(name))
    return publish(name, contents)
}

/**
 * Ensure that multiple bundles are loaded
 *
 * @param {{ [locale: string]: import('vue-i18n').LocaleMessages }} bundles
 * @return {Promise<Set>}
 */
export const ensureBundlesLoaded = async (bundles = {}) => {
    await Promise.all(
        Object.entries(bundles).map(([setName, setMessages]) =>
            ensureLoaded(setName, setMessages),
        ),
    )
    return loadedLangs
}

/**
 *
 * @param locale
 * @returns {import('vue-i18n').I18n<{}, {}, {}, string, true>}
 */
export const createVueI18nProvider = (locale = getLocale()) => {
    const i18n = createI18n({
        locale,
        fallbackLocale: 'en',
        silentTranslationWarn: false,
    })

    setListener(i18n.global)
    return i18n
}
