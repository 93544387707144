<template>
    <section
        :class="{ hidden: messages.length === 0 }"
        class="alert-container container"
    >
        <message-alert
            v-for="message of messages"
            :key="message.title"
            :title="message.title"
            :type="message.type"
            closable
        >
            {{ message.message }}
        </message-alert>
    </section>
</template>

<script>
import MessageAlert from './MessageAlert'

export default {
    components: {
        MessageAlert,
    },
    props: {
        messages: {
            type: Array,
            required: true,
        },
    },
}
</script>
