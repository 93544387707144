import { createApp } from 'vue'
import SvgVue from 'svg-vue3'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { createVueI18nProvider } from './shared/services/TranslationService'
import components from './portal/component-registry'
import store from './portal/store'

if (typeof process.env.MIX_BUGSNAG_API_KEY === 'string') {
    Bugsnag.start({
        apiKey: process.env.MIX_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        appVersion: process.env.MIX_BUGSNAG_APP_VERSION,
        releaseStage: process.env.MIX_BUGSNAG_RELEASE_STAGE,
        // Prevent tracking the user.
        generateAnonymousId: false,
        autoTrackSessions: false,
    })
}

createApp({ components })
    .use(Bugsnag.getPlugin('vue'))
    .use(store(window.__VUEX_DEFAULT_STATE))
    .use(createVueI18nProvider())
    .use(SvgVue)
    .mount('#app')
