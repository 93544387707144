/** @var {WhiteBox.EnumObject<WhiteBox.LearningObjectType>} */
const LearningObjectType = {
    ANIMATION: 'animation',
    AUDIO: 'audio',
    CONTENT: 'content',
    FLASHCARD: 'flashcard',
    IFRAME: 'iframe',
    MISSION: 'mission',
    MOVIE: 'movie',
    QUIZ: 'quiz',
}

export default LearningObjectType
