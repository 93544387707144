import createStore from '../shared/store'
import * as modules from './modules'

export default (hydration) => {
    const store = createStore(modules)
    if (typeof hydration !== 'undefined') {
        // Make sure the default state exists within the hydration
        Object.keys(modules).forEach((moduleName) => {
            if (!(moduleName in hydration)) {
                hydration[moduleName] = modules[moduleName].state
            }
        })
        store.replaceState(hydration)
    }
    return store
}
