import {
    format as fmt,
    formatDistance as fd,
    formatDistanceStrict as fds,
} from 'date-fns'
import { enGB, nl } from 'date-fns/locale'
import { getLocale } from '../utils'

const supportedLocales = { en: enGB, nl }

const getOptions = (options = {}) => {
    if (!('locale' in options)) {
        options.locale = supportedLocales[getLocale()]
    }
    return options
}

export const formatDistance = (date, baseDate, options) =>
    fd(date, baseDate, getOptions(options))

export const format = (date, formatString, options) =>
    fmt(date, formatString, getOptions(options))

export const formatDistanceStrict = (date, baseDate, options) =>
    fds(date, baseDate, getOptions(options))
