import { Store } from 'vuex'
import * as actions from './actions'
import * as mutations from './mutations'

const defaultState = {
    user: null,
    organisation: null,
}

const storeBuilder = (modules = {}) =>
    new Store({
        strict: process.env.NODE_ENV !== 'production',
        actions,
        mutations,
        modules,
        state: defaultState,
    })

export default storeBuilder
