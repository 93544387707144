export default class HttpError extends Error {
    name = 'HttpError'

    #code

    #content

    constructor(code, content) {
        super(content.message)
        this.#code = code
        this.#content = content
    }

    /**
     *
     * @param {Response}response
     * @return {HttpError}
     */
    static async fromResponse(response) {
        const parsedContent = await HttpError.#parseContent(response)
        return new HttpError(response.status, parsedContent)
    }

    /**
     * @param {Response} response
     * @return {Object} Standardized response content
     */
    static #parseContent = async (response) => {
        const contentType = response.headers.get('content-type')
        if (contentType.toLowerCase() === 'application/json') {
            return response.json()
        }

        return {
            message: await response.text(),
        }
    }

    getCode = () => this.#code

    getContent = () => this.#content
}
